
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { I18nUtils } from "@/latipay/utils/I18nUtils";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().email().required().label("Username"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.LOGOUT);

      // Send login request
      await store.dispatch(Actions.LOGIN, values);

      if (submitButton.value) {
        //Deactivate indicator
        submitButton.value.removeAttribute("data-kt-indicator");
        submitButton.value.disabled = false;
      }
      const [errorName] = Object.keys(store.getters.getErrors);
      const [error] = Object.keys(store.getters.getErrors);

      console.log(store);
      console.log(error);
      if (!error) {
        Swal.fire({
          text: "You have successfully logged in!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({ name: "merchants" });
        });
      } else {
        console.log("12");
        // console.log(store.getters.getErrors[error]);
        // I18nUtils.translateGlobal(store.getters.getErrors[error])
        Swal.fire({
          text: "Username or password is incorrect",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: I18nUtils.translateGlobal(
                  "latipay.pages.signIn.tryAgain"
          ),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });

      }

      // //Deactivate indicator
      // submitButton.value?.removeAttribute("data-kt-indicator");
      // // eslint-disable-next-line
      //   submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      I18nUtils
    };
  },
});
